.checkout-form{
  text-align: center;
}
.input-email-container{
  border: 4px solid lightgray;
  width: 362px;
  padding: 5px 0px 5px 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 28px;
}
#email{
  margin-left: 5px;
  border: none;
  outline: none;
}
.card-number-container{
  border: 4px solid lightgray;
    width: 362px;
    padding: 10px 0 10px 6px;
    margin-left: auto;
    margin-right: auto;
    font-size: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
  #card-number-element{
    margin-left: 5px;
    width: 100%;
  }
 
  .date-cvc-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .exp-container{
    border: 4px solid lightgrey;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 3px;
  }
  #card-expiry-element{
   width: 100%;
   margin-left: 5px;
  }
  .cvc-container{
    border: 4px solid lightgrey;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 3px;
  }
  #card-cvc-element{
    margin-left: 5px;
    width: 100%;
  }
  .submit-button{
    margin-top: 30px;
    margin-bottom: 50px;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background-color: #44b1f6;
    color: white;
    font-size: 30px;
    border: none;
  }
  .submit-success{
    background-color: rgb(102, 179, 24);
  }
  

#modal-pop{
    background-color: #F5F5F7;
    position: absolute;
    top: 110px;
    left: 35%;
    width: 400px;
    z-index: 999;
    border-radius: 10px;
    text-align: center;
  }
  #modal-overlay{
    background: rgba(29, 16, 16, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;

  }
.popUp-logo{
  border: 6px solid lightgray;
    border-radius: 50%;
    height: 120px;
    width: 124px;
    margin-top: -50px;

} 
  .close-modal-button{
    border-radius: 50%;
    border: 2px solid lightgray;
    background:lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-bottom: auto;
    margin-top: -65px;
    font-size: 32px;
    color: white;
  }
 

  .form-container{
    padding: 5px 20px 10px 20px ;
  }
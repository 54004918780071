.upgrade-message-container{
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-align: center;
    font-size: 24px;
}
.upgrade-message-container>button{
    background-color: #871F78;
    color: white;
    padding: 20px;
}
.plan-container{
    margin-top: 5%;
    margin-right: 0;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
}

.planCatagory{
    padding: 16px;
}
.enterprise{
    background-color: #9ACD32;
    color: white;
}
.business{
    color: white;
    background-color:#871F78 ;
}
.basic{
    color: white;
    background-color:#0000FF ;
}
.starter{
    color: white;
    background-color:#FFA500 ;
}
.buyNow-button{
    border: 2px solid #0EBFE9;
    background-color: #1ABC9C;
    color: white;
    padding: 5px;
    width: 170px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 500;
}
.plan-info{
display: flex;
justify-content:center;
align-items: center;
font-family: Arial, Helvetica, sans-serif;
font-size: 22px;
font-weight: 400; 
}
.plan-info p{
    margin: 5px;
    text-align: left;
}
.single-plan{
    
    margin: 5px;
    padding: 20px;
    text-align: center;
    box-shadow: 2px 1px 2px 2px gray;

}








   